import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import './App.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import Home from './Views/Home';
import About from './Views/About';
import SideBar from './Components/SideBar';
import Navigation from './Components/Navigation';
import MyWork from './Views/MyWork';
import Skills from './Views/Skills';
import Contact from './Views/Contact';
import Footer from './Components/Footer';
import {Provider}  from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import {createStore, combineReducers} from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr';
const reducers = {
  // ... other reducers ...
  toastr: toastrReducer // <- Mounted at toastr.
}
const reducer = combineReducers(reducers)
const store = createStore(reducer)

const App: React.FC = () => {
  return (
    <Provider
    store={store}
    >
      <Router>
        <div className="d-none d-md-block">
          <SideBar/>
        </div>
        <div className="d-block d-md-none">
          <Navigation/>
        </div>
        <div className="app-wrapper container-fluid bg-dark">
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route path="/about">
              <About/>
            </Route>
            <Route path="/skills">
              <Skills/>
            </Route>
            <Route path="/my-work">
              <MyWork/>
            </Route>
            <Route path="/contact">
              <Contact/>
            </Route>
          </Switch>
        </div>
        <Footer/>
      </Router>
      <ReduxToastr
      timeOut={10000}
      newestOnTop={false}
      preventDuplicates
      // getState={(state: any) => state.toastr} // This is the default
      transitionIn="bounceIn"
      transitionOut="bounceOut"
      position="bottom-right"
      progressBar
      closeOnToastrClick/>
    </Provider>
  );
}

export default App;
