import { MDBAnimation, MDBBtn, MDBInput } from "mdbreact";
import React, { useEffect } from "react";
import { toastr } from "react-redux-toastr";
import "../App.css";
import GoogleMaps from "../Components/GoogleMaps";
import { ContactForm, MailService } from "../services/Mail.service";

const Contact: React.FC = () => {
  const mailService = new MailService();
  const contactForm: ContactForm = {
    name: "",
    email: "",
    service: "",
    budget: "",
    message: "",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getLetter = (letter: string, n: number) => {
    return (
      <div style={{ display: "inline-block" }}>
        <MDBAnimation type="fadeIn" delay={n * 0.1 + "s"}>
          <span>{letter}</span>
        </MDBAnimation>
      </div>
    );
  };

  const handleNameChange = (event: any) => {
    contactForm.name = event.target.value;
  };

  const handleEmailChange = (event: any) => {
    contactForm.email = event.target.value;
  };

  const handleServiceChange = (event: any) => {
    contactForm.service = event.target.value;
  };

  const handleBudgetChange = (event: any) => {
    contactForm.budget = event.target.value;
  };

  const handleMessageChange = (event: any) => {
    contactForm.message = event.target.value;
  };

  const emailIsValid = () =>
    contactForm.email !== "" &&
    contactForm.email.includes("@") &&
    contactForm.email.includes(".");

  const isEmptyString = (str: string) => str === "";

  const formIsValid = () => {
    return (
      emailIsValid() &&
      !isEmptyString(contactForm.message) &&
      !isEmptyString(contactForm.service)
    );
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!formIsValid()) {
      toastr.error(
        "Message not sent",
        "You need to fill out the form to send a message."
      );
      return;
    }
    mailService.SendEmail(contactForm).then(
      () => {
        toastr.success(
          "Message sent",
          "Email with your message was sent successfully, I will get back to you as soon as possible."
        );
      },
      (err) => {
        console.error(err);
        toastr.error(
          "Message failed",
          "Email with your message was not sent, check your connection or try again later."
        );
      }
    );
  };

  return (
    <div className="container-fluid minh-full w-100 bg-dark contact-page">
      <h1 className="page-title color-white">
        {/* Contact */}
        {getLetter("C", 1)}
        {getLetter("o", 2)}
        {getLetter("n", 3)}
        {getLetter("t", 4)}
        {getLetter("a", 5)}
        {getLetter("c", 6)}
        {getLetter("t", 7)}
      </h1>
      <MDBAnimation type="fadeInUp" delay="1s">
        <div className="row">
          <div className="col-12 map-div">
            <GoogleMaps />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <h4 className="section-title mb-3 color-white">ADDRESS</h4>
            <ul className="address">
              <li>Anton Nystedt</li>
              <li>Hyttlidgatan 69B</li>
              <li>931 37, Skellefteå</li>
              <li>Sweden</li>
            </ul>
          </div>
          <div className="col-md-8">
            <form className="form-group" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <MDBInput
                    type="text"
                    label="Your Name *"
                    icon="user"
                    onChange={handleNameChange}
                  />
                </div>
                <div className="col-md-6">
                  <MDBInput
                    type="email"
                    label="Your Email *"
                    icon="at"
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="col-md-6">
                  <MDBInput
                    type="text"
                    label="Service"
                    icon="briefcase"
                    onChange={handleServiceChange}
                  />
                </div>
                <div className="col-md-6">
                  <MDBInput
                    type="text"
                    label="Budget"
                    icon="wallet"
                    onChange={handleBudgetChange}
                  />
                </div>
                <div className="col-12">
                  <MDBInput
                    type="textarea"
                    rows="5"
                    label="Your Message *"
                    icon="envelope"
                    onChange={handleMessageChange}
                  />
                </div>
                <MDBBtn
                  type="submit"
                  value="Submit"
                  block
                  outline
                  color="light"
                >
                  Send message
                </MDBBtn>
              </div>
            </form>
          </div>
        </div>
      </MDBAnimation>
    </div>
  );
};

export default Contact;
