import { MDBListGroup } from 'mdbreact';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
    closeMenu: any;
}

const NavigationMenu: React.FC<Props> = (props: Props) => {
    return (
        <MDBListGroup className="nav-menu" style={{ width: "100%" }}>
            <NavLink exact to="/" activeClassName="active" onClick={props.closeMenu}>
                <i className="fas fa-home"></i>
                <p>HOME</p>
            </NavLink>
            <NavLink to="/about" activeClassName="active" onClick={props.closeMenu}>
                <i className="fas fa-user"></i>
                <p>ABOUT</p>
            </NavLink>
            <NavLink to="/skills" activeClassName="active" onClick={props.closeMenu}>
                <i className="fas fa-cog"></i>
                <p>SKILLS</p>
            </NavLink>
            <NavLink to="/my-work" activeClassName="active" onClick={props.closeMenu}>
                <i className="fas fa-eye"></i>
                <p>MY WORK</p>
            </NavLink>
            <NavLink to="/contact" activeClassName="active" onClick={props.closeMenu}>
                <i className="fas fa-envelope"></i>
                <p>CONTACT</p>
            </NavLink>
        </MDBListGroup>
    );
}

export default NavigationMenu;
