import axios, { AxiosRequestConfig } from 'axios';
import '../App.css';

export interface ContactForm {
    name: string;
    email: string;
    service: string;
    budget: string;
    message: string;
}

export class MailService {
    baseUrl = 'https://antonnystedt.com/';
    // baseUrl = 'http://localhost:4000/';
  
    SendEmail = (contactForm: ContactForm) => {
        const options: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        }; 
        return axios.post(this.baseUrl + 'api/mail', contactForm, options);
    }
}