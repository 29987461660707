import React, { useEffect } from "react";
import "../App.css";
import { MDBAnimation, MDBBtn } from "mdbreact";
import Projects from "../Components/Projects";
import { Link } from "react-router-dom";
// import Instagram from "../Components/Instagram";

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const letterHoveredOnEnter = (e: any) => {
    e.target.style.color = "#00aaff";
    e.target.style.fontSize = "90px";
  };

  const letterHoveredOnLeave = (e: any) => {
    e.target.style.color = "";
    e.target.style.fontSize = "";
  };

  const getLetter = (letter: string, n: number) => {
    let classes = "";
    if (letter === "m" || letter === "b") classes = "mr-3";
    return (
      <div style={{ display: "inline-block" }}>
        <MDBAnimation type="fadeIn" delay={n * 0.1 + "s"}>
          <span
            onMouseEnter={letterHoveredOnEnter}
            onMouseLeave={letterHoveredOnLeave}
            className={classes}
          >
            {letter}
          </span>
        </MDBAnimation>
      </div>
    );
  };

  return (
    // <MDBAnimation type="fadeIn" duration={2}>
    <div className="home-page">
      <div className="home-page__start h-full">
        <div className="pl-5">
          <h1 className="color-white">
            {/* Hi, */}
            {getLetter("H", 1)}
            {getLetter("i", 2)}
            {getLetter(",", 3)}
            <br />
            {/* I'm*/}
            {getLetter("I", 4)}
            {getLetter("'", 5)}
            {getLetter("m", 6)}
            {/* Anton, */}
            {getLetter("A", 7)}
            {getLetter("n", 8)}
            {getLetter("t", 9)}
            {getLetter("o", 10)}
            {getLetter("n", 11)}
            {/* { getLetter(',', 12) }
              <br/> */}
            {/* Web */}
            {/* { getLetter('W', 13) }
              { getLetter("e", 14) }
              { getLetter('b', 15) } */}
            {/* Developer */}
            {/* { getLetter('D', 16) }
              { getLetter("e", 17) }
              { getLetter('v', 18) }
              { getLetter("e", 19) }
              { getLetter('l', 20) }
              { getLetter('o', 21) }
              { getLetter("p", 22) }
              { getLetter('e', 23) }
              { getLetter('r', 24) }
              { getLetter('.', 25) } */}
          </h1>
          <MDBAnimation type="fadeInUp" delay="1.2s">
            <p className="color-grey">Developer / Designer</p>
            <Link to="/contact">
              <MDBBtn outline color="primary" className="mt-3 ml-0">
                Contact me!
              </MDBBtn>
            </Link>
          </MDBAnimation>
        </div>
      </div>
      <div className="wow fadeInUp" data-wow-delay="0.4s">
        <h4
          className="section-title color-white wow fadeInUp"
          data-wow-delay="0.4s"
        >
          SELECTED PROJECTS
        </h4>
        <Projects />
      </div>
      {/* <div className="wow fadeInUp" data-wow-delay="0.4s">
          <h4 className="section-title color-white">LATEST ON INSTAGRAM</h4>
          <Instagram/>
        </div> */}
      <div className="wow fadeInUp" data-wow-delay="0.4s">
        <h4 className="section-title color-white text-center pl-0 mb-0">
          NEED A DEVELOPER OR DESIGNER?
        </h4>
        <div className="d-flex align-items-center justify-content-center h-20rem">
          <Link to="/contact">
            <h1 className="color-white contact-link">
              Let's work together <i className="fas fa-arrow-right"></i>
            </h1>
          </Link>
        </div>
      </div>
    </div>
    // </MDBAnimation>
  );
};

export default Home;
