import { MDBListGroup } from 'mdbreact';
import React from 'react';
import { NavLink } from 'react-router-dom';
import '../App.css';

const SideBar: React.FC = () => {
    return (
        <div id="side-bar">
            <p className="logo"></p>

            <MDBListGroup style={{ width: "100%" }}>
                <NavLink exact to="/" activeClassName="active">
                    <i className="fas fa-home"></i>
                    <p>HOME</p>
                </NavLink>
                <NavLink to="/about" activeClassName="active">
                    <i className="fas fa-user"></i>
                    <p>ABOUT</p>
                </NavLink>
                <NavLink to="/skills" activeClassName="active">
                    <i className="fas fa-cog"></i>
                    <p>SKILLS</p>
                </NavLink>
                <NavLink to="/my-work" activeClassName="active">
                    <i className="fas fa-eye"></i>
                    <p>MY WORK</p>
                </NavLink>
                <NavLink to="/contact" activeClassName="active">
                    <i className="fas fa-envelope"></i>
                    <p>CONTACT</p>
                </NavLink>
            </MDBListGroup>

            <ul className="social-links">
                <li>
                    <a href="https://www.linkedin.com/in/antonnystedt" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/antonnystedt" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="https://instagram.com/antonnystedt" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                </li>
                <li>
                    <a href="https://facebook.com/antonnystedt" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default SideBar;
