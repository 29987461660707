import { MDBAnimation } from "mdbreact";
import React, { useEffect } from "react";
import "../App.css";

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getLetter = (letter: string, n: number) => {
    let classes = "";
    if (letter === "t") classes = "mr-3";
    return (
      <div style={{ display: "inline-block" }}>
        <MDBAnimation type="fadeIn" delay={n * 0.1 + "s"}>
          <span className={classes}>{letter}</span>
        </MDBAnimation>
      </div>
    );
  };

  return (
    <div className="about container-fluid minh-full w-100 bg-dark color-white pb-5">
      <h1 className="page-title">
        {/* About */}
        {getLetter("A", 1)}
        {getLetter("b", 2)}
        {getLetter("o", 3)}
        {getLetter("u", 4)}
        {getLetter("t", 5)}
        {/* Me */}
        {getLetter("M", 6)}
        {getLetter("e", 7)}
      </h1>
      <div className="row wow fadeInUp" data-wow-delay="0.8s">
        <div className="col-xl-6">
          <h4 className="section-title">Who am I</h4>
          <p>
            For as long as I can remember I've thought that some day I will
            become a pilot and that's what I've always wanted. But the second I
            started my first programming course in high school I knew that was
            what I was supposed to do. <br />
            Ever since that moment I've been spending most of my time trying to
            learn as much as I can to be able to one day work with
            webdevelopment.
            <br />
            Today I work as a Frontend Developer and I'm loving it. I've been
            studying Interaction Technology and Design at Umeå University for
            the past 2 years but I realised that I was learning a lot quicker
            (and having a lot more fun), while I was working! So I decided to
            quit school and start my carreer as a developer, using the skills
            that I had aquired through school, but mostly through my own
            personal projects.
            <br />I also consider myself a leader and I've shown that in my past
            few jobs as well as in my career as a floorball coach.
          </p>
          <h4 className="section-title">Interests</h4>
          <p>
            I've always been a sportsnerd and ever since I learned to walk I've
            been playing all kinds of sports, including football, floorball and
            hockey. But in my teens I got a huge interest for gaming, this led
            me into an interest for computers and eventually programming. When I
            was gaming I loved to create different logos and gamingrelated
            content in Photoshop and started to fall in love with design. This
            combined with my interest for programming led me to a huge interest
            for frontend development.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
