import { MDBBtn } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import "../App.css";

const Footer: React.FC = () => {
  return (
    <div className="container-fluid bg-bar footer">
      <div className="row">
        <div className="col-md-4">
          <h4 className="footer-title">Contact</h4>
          <p>
            Feel free to send me an email with any request you might have. I
            will get back to you as soon as possible.
          </p>
          <Link to="/contact">
            <MDBBtn className="btn-mail" gradient="blue">
              <i className="fas fa-envelope"></i>
              <span>Contact me!</span>
            </MDBBtn>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
