import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import NavigationMenu from './NavigationMenu';

const Navigation: React.FC = () => {
    const [showMenu, setShowMenu] = useState(false);

    const maskTransitions = useTransition(showMenu, null, {
        from: { position: 'absolute', opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }, 
    })

    const menuTransitions = useTransition(showMenu, null, {
        from: { opacity: 0, transform: 'translateX(-100%)' },
        enter: { opacity: 1, transform: 'translateX(0%)' },
        leave: { opacity: 0, transform: 'translateX(-100%)' }, 
    })

    return (
        <nav>
            <span className="text-xl">
                <i className="fas fa-bars"
                    onClick={() => setShowMenu(!showMenu)}
                /> 
            </span>

            {/* Black background beside the menu */}
            { 
                maskTransitions.map(({ item, key, props }) => 
                    item && 
                    <animated.div 
                        key={key} 
                        style={props}
                        className="bg-black__o-80 p-fixed top-0 left-0 w-full h-full__all z-50"
                        onClick={() => setShowMenu(false)}
                    >
                    </animated.div>
                )
            }

            {/* The menu */}
            { 
                menuTransitions.map(({ item, key, props }) => 
                    item && 
                    <animated.div 
                        key={key}
                        style={props}
                        className="p-fixed bg-bar top-0 left-0 w-75 h-full__all z-50 shadow p-3"
                    >
                        <NavigationMenu closeMenu={() => setShowMenu(false)}/>
                    </animated.div>
                )
            }
        </nav>
    );
}

export default Navigation;
