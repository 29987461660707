import React from "react";
import "../App.css";
import { MDBAnimation, MDBBtn, MDBMask, MDBView } from "mdbreact";

const Projects: React.FC = () => {
  const linkToGruppkort = () => {
    window.open("https://gruppkort.se/", "_blank");
  };

  const linkToHaraldInvest = () => {
    window.open("https://haraldinvest.se/", "_blank");
  };

  const linkToSkiftSchemaAppStore = () => {
    window.open(
      "https://apps.apple.com/se/app/skiftschema-pro/id1495071386",
      "_blank"
    );
  };

  const linkToSkiftSchemaGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=ion.skiftschema",
      "_blank"
    );
  };

  const linkToPixelsAppStore = () => {
    window.open(
      "https://apps.apple.com/se/app/pixels-party-games/id149477159",
      "_blank"
    );
  };

  const linkToPixelsGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=ion.pixels",
      "_blank"
    );
  };

  //   const linkToAvaStories = () => {
  //     window.open(
  //       "https://framer.com/share/AVA--xhECLRIgiNitagpdjxNp/NkbW2VTEc?fullscreen=1",
  //       "_blank"
  //     );
  //   };

  const linkToHugoBeck = () => {
    window.open("https://www.hugobeck.se", "_blank");
  };

  return (
    <MDBAnimation type="fadeInUp" delay="0.2s">
      <div className="p-3 px-5">
        <div className="row mx-0">
          <div className="img-col img-col__left col-12 col-md-6">
            <MDBView hover>
              <div className="img-fluid project-img img-gruppkort"></div>
              <MDBMask overlay="black-strong">
                <h4 className="img-title">
                  Gruppkort - Send group cards online
                </h4>
                <div className="p-3 color-white">
                  <p className="img-text">
                    Gruppkort.se is me and my partners latest project. It is a
                    website for sending group cards with personal messages to
                    one lucky person.
                    <br />I was responsible for the UX-design, UI-design and the
                    frontend development. I designed it using Figma and then
                    developed the frontend with React TS. The backend of the
                    project is a .NET server, I did some work on it but it was
                    mostly written and designed by my partner.
                  </p>
                </div>
                <div className="project-button">
                  <MDBBtn
                    outline
                    color="light"
                    onClick={() => linkToGruppkort()}
                  >
                    Visit website
                  </MDBBtn>
                </div>
              </MDBMask>
            </MDBView>
          </div>
          <div className="img-col img-col__right col-12 col-md-6">
            <MDBView hover>
              <div className="img-fluid project-img img-1"></div>
              <MDBMask overlay="black-strong">
                <h4 className="img-title">
                  Harald Invest - The best economic advisor you can get.
                </h4>
                <div className="p-3 color-white">
                  <p className="img-text">
                    HaraldInvest.com was my first paid website project and a
                    project that I'm very proud of.
                    <br />
                    It is the official website for a economic advisor company
                    called Harald Invest, they wanted a modern, yet elegant
                    look, that had a professional feel and I personally feel
                    like I nailed it.
                  </p>
                </div>
                <div className="project-button">
                  <MDBBtn
                    outline
                    color="light"
                    onClick={() => linkToHaraldInvest()}
                  >
                    Visit website
                  </MDBBtn>
                </div>
              </MDBMask>
            </MDBView>
          </div>
          <div className="img-col img-col__left col-12 col-md-6">
            <MDBView hover>
              <div className="img-fluid project-img img-5"></div>
              <MDBMask overlay="black-strong">
                <h4 className="img-title">Hugo Beck Nordic</h4>
                <div className="p-3 color-white">
                  <p className="img-text">
                    Hugo Beck Nordic reached out to me and wanted a brand new
                    website that resembled the website of their international
                    department at www.hugobeck.com. I made the website using
                    Angular and gave it a design resembling their international
                    one but with a more modern and clean look.
                  </p>
                </div>
                <div className="row m-0 p-3 p-absolute bottom-0 w-100 d-flex justify-content-between">
                  <MDBBtn
                    outline
                    color="light"
                    onClick={() => linkToHugoBeck()}
                  >
                    Visit website
                  </MDBBtn>
                </div>
              </MDBMask>
            </MDBView>
          </div>
          <div className="img-col img-col__right col-12 col-md-6">
            <MDBView hover>
              <div className="img-fluid project-img img-3"></div>
              <MDBMask overlay="black-strong">
                <h4 className="img-title">
                  Pixels Party Games - Play games live with your friends
                </h4>
                <div className="p-3 color-white">
                  <p className="img-text">
                    Pixels Party Games is a crossplatform app that I've built
                    together with a colleague and is an app filled with lots of
                    fun social games that you can play together with your
                    friends live on multiple devices. We have built the entire
                    app together from scratch, but my main focus has been
                    creating the games and most of the frontend development,
                    while my colleague has been responsible to set up the API
                    and most of the backend. The frontend of the app is
                    developed using Ionic 4 with angular.
                    <br />
                    Pixels Party Games is live on the Google Play Store.
                  </p>
                </div>
                <div className="row m-0 p-3 p-absolute bottom-0 w-100 d-flex justify-content-between">
                  {/* <MDBBtn
                    outline
                    color="light"
                    onClick={() => linkToPixelsAppStore()}
                  >
                    Show on AppStore
                  </MDBBtn> */}
                  <MDBBtn
                    outline
                    color="light"
                    onClick={() => linkToPixelsGooglePlay()}
                  >
                    Show on Google Play
                  </MDBBtn>
                </div>
              </MDBMask>
            </MDBView>
          </div>
          <div className="img-col img-col__left col-12 col-md-6">
            <MDBView hover>
              <div className="img-fluid project-img img-4"></div>
              <MDBMask overlay="black-strong">
                <div className="w-50">
                  <h4 className="img-title">Ava Stories</h4>
                </div>
                <div className="p-3 color-white">
                  <p className="img-text">
                    Ava Stories is an app that I got hired to create a prototype
                    for, this was my first job as a UI / UX Designer apart from
                    my personal projects. Ava Stories is an app for reading and
                    listening to erotic stories so I tried creating a prototype
                    with a modern feel and look with a kind of erotic feel.
                  </p>
                </div>
                {/* <div className="row m-0 p-3 p-absolute bottom-0 w-100 d-flex justify-content-between">
                  <MDBBtn
                    outline
                    color="light"
                    onClick={() => linkToAvaStories()}
                  >
                    Check out the prototype!
                  </MDBBtn>
                </div> */}
              </MDBMask>
            </MDBView>
          </div>
          <div className="img-col img-col__right col-12 col-md-6">
            <MDBView hover>
              <div className="img-fluid project-img img-2"></div>
              <MDBMask overlay="black-strong">
                <h4 className="img-title">
                  Skiftschema - Get your schedule 50 years ahead of time.
                </h4>
                <div className="p-3 color-white">
                  <p className="img-text">
                    Skiftschema is an app that I've built by myself and is an
                    app for people who work shifts and want to be able to check
                    their schedule easily. I first had the idea for the app when
                    I was working shift at a factory and needed to plan ahead a
                    few months, but our schedule hadn't been released yet, So I
                    started with developing a small program in C. Then I
                    thought, if I have this problem, maybe other people have it
                    to, so I decided to build an app for it, that later became
                    Skiftschema. The frontend of the app is developed using
                    Ionic 4 with angular.
                    <br />
                    Skiftschema is live on the Google Play Store.
                  </p>
                </div>
                <div className="row m-0 p-3 p-absolute bottom-0 w-100 d-flex justify-content-between">
                  {/* <MDBBtn
                    outline
                    color="light"
                    onClick={() => linkToSkiftSchemaAppStore()}
                  >
                    Show on AppStore
                  </MDBBtn> */}
                  <MDBBtn
                    outline
                    color="light"
                    onClick={() => linkToSkiftSchemaGooglePlay()}
                  >
                    Show on Google Play
                  </MDBBtn>
                </div>
              </MDBMask>
            </MDBView>
          </div>
        </div>
      </div>
    </MDBAnimation>
  );
};

export default Projects;
