import { MDBAnimation } from 'mdbreact';
import React, { useEffect } from 'react';
import '../App.css';
import Projects from '../Components/Projects';

const MyWork: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getLetter = (letter: string, n: number) => {
    let classes = '';
    if (letter === 'y') classes = 'mr-3';
    return <div style={{ display: 'inline-block' }}>
      <MDBAnimation type="fadeIn" delay={n * 0.1 + 's'}>
        <span 
          className={classes}
        >
          { letter }
        </span>
      </MDBAnimation>
    </div>
  }

  return (
    <div className="container-fluid minh-full w-100 bg-dark">
      <h1 className="page-title color-white">
        {/* My */}
        { getLetter('M', 1) }
        { getLetter('y', 2) }
        {/* Work */}
        { getLetter('W', 3) }
        { getLetter('o', 4) }
        { getLetter('r', 5) }
        { getLetter('k', 6) }
      </h1>
      <MDBAnimation type="fadeInUp" delay="0.7s">
        <Projects/>
      </MDBAnimation>
    </div>
  );
}

export default MyWork;
