import { MDBAnimation } from "mdbreact";
import React, { useEffect } from "react";
import "../App.css";

const Skills: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getLetter = (letter: string, n: number) => {
    return (
      <div style={{ display: "inline-block" }}>
        <MDBAnimation type="fadeIn" delay={n * 0.1 + "s"}>
          <span>{letter}</span>
        </MDBAnimation>
      </div>
    );
  };

  return (
    <div className="skills container-fluid minh-full w-100 bg-dark color-white">
      <h1 className="page-title">
        {/* Skills */}
        {getLetter("S", 1)}
        {getLetter("k", 2)}
        {getLetter("i", 3)}
        {getLetter("l", 4)}
        {getLetter("l", 5)}
        {getLetter("s", 6)}
      </h1>
      <div className="row wow fadeInUp" data-wow-delay="0.8s">
        <div className="col-xl-6 pb-5">
          <p className="py-2">
            My main area of expertise is frontend development, as well as UX and
            UI design.
            <br />
            <br />
            HTML, CSS, TypeScript and JavaScript is my comfort zone and I mostly
            combine those languages within React development to build websites
            or crossplatform mobile applications.
          </p>
          <h4 className="section-title">Experience</h4>
          <p className="py-2">
            {/* North Kingdom */}
            <a
              className="link font-weight-bold"
              href="https://northkingdom.com"
              target="_blank"
              rel="noreferrer"
            >
              Developer - North Kingdom Design & Communication AB
            </a>
            <br />
            At North Kingdom I currently work as a developer, where I help build
            products used by millions each day. My work at North Kingdom
            includes:
            <ul className="dashed mt-2">
              <li>Frontend development (React TS, Vue TS, Next TS, Nuxt TS)</li>
              <li>Backend development (Node TS)</li>
            </ul>
          </p>
          <p className="py-2">
            {/* Senseworks */}
            <a
              className="link font-weight-bold"
              href="https://www.senseworks.io/"
              target="_blank"
              rel="noreferrer"
            >
              Developer/Designer - SenseWorks AB
            </a>
            <br />
            At Senseworks I helped a startup build a tool for the financial
            reporting industry to exchange and collaborate on insights from
            financial data. This was fun, but also very challenging work. I
            learned a lot about the startup world and what it takes to develop a
            product. My work at Senseworks included:
            <ul className="dashed mt-2">
              <li>Frontend development (React TS)</li>
              <li>UX design (Figma)</li>
              <li>UI design (Figma)</li>
              <li>Backend development (Node TS)</li>
            </ul>
          </p>
          {/* Freelance */}
          <p className="py-2">
            <span className="font-weight-bold">
              Developer/Designer - Freelance
            </span>
            <br />
            During my time as a student at Umeå University, I also worked as a
            freelancer. Most projects included doing all parts of a website,
            including:
            <ul className="dashed mt-2">
              <li>Frontend development</li>
              <li>Backend development</li>
              <li>UX design</li>
              <li>UI design</li>
            </ul>
          </p>
          {/* Boliden */}
          <p className="py-2">
            <span className="font-weight-bold">Process Operator - Boliden</span>
            <br />
            As a process operator, I had very varied tasks. These included,
            among others:
            <ul className="dashed mt-2">
              <li>Monitor and control a large industrial process</li>
              <li>Monitor and control a steam boiler</li>
              <li>Backend development</li>
              <li>Blast with dynamite</li>
              <li>Operating a forklift</li>
              <li>Inspection of lifting devices</li>
            </ul>
          </p>
          <h4 className="section-title">Education</h4>
          <p>
            In high school i studied IT and Media and then I continued by
            studying the Master of Science Programme in Interaction Technology
            and Design at Umeå University for 2 years.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Skills;
